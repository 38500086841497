.skill-tree {

  .row {
    height: 0;
    margin: 80px 0;

    .ability-slot {
      display: inline-block;
      height: 0;
      width: 0;
      margin: 0 40px;

      & > * {
        transform: translate(-50%, -50%);
      }

    }

  }

}