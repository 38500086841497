
.overlay {
  position: absolute;
  left: 0;
  width: 100vw;
  top: 0;
  height: 100vh;
  background-color: rgba(14, 14, 14, 0.85);
  color: white;
  transition-duration: 0.3s;
  opacity: 1;

  &.hidden {
    top: 100vh;
    opacity: 0;
  }

  * {
    // border: 1px solid red;
  }
  
  .close {
    position: absolute;
    top: 160px;
    right: 160px;

    cursor: pointer;

    i {
      font-size: 48px;
    }
  }

  .menu {
    position: absolute;
    left: 160px;
    top: 50%;
    transform: translateY(-50%);

    a {
      display: block;

      font-family: Fredoka One;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 52px;

      color: inherit;
      text-decoration: none;

      margin-bottom: 20px;

      transition-duration: 0.3s;

      cursor: pointer;

      &:hover {
        opacity: 0.5;
        transition-duration: 0.1s;
      }

      &:active, &.active {
        color: #37CFFF;
        opacity: 1;
        transition-duration: 0.1s;
      }
    }
  }

  
  .talent-area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}