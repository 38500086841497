
.interface .level-info #level {
    display: inline-block;
    font-size: 32px;
    margin-right: 12px;
    font-size: 16px;
    line-height: 32px;
    width: 32px;
    height: 32px;
    text-align: center;
    background: #37CFFF;  
    color: #000000;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
  .interface .level-info .experience {
    display: inline-block;
  }
  .interface .level-info .experience #experience-bar {
    height: 12px;
    width: 240px;
    background: #37CFFF;
    border-radius: 2px;
    margin-bottom: 4px;
  }